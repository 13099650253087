export const Routes = {
  // API_HOSTNAME: "https://www.online.measervices.in:8443",
  // WEBSOCKET_HOSTNAME: "wss://www.online.measervices.in:8443",
  API_HOSTNAME: "https://www.portal.measervices.in",
  WEBSOCKET_HOSTNAME: "wss://www.portal.measervices.in:8081",

  // API_HOSTNAME: "http://192.168.1.153:8081",
  // WEBSOCKET_HOSTNAME: "ws://192.168.1.153:8081",

  API_HOSTNAME_WP: "https://api.wapmonkey.com",

  LOGIN: "login",
  OTP: "otp",
  EmailOtp: "emailotp",
  VERIFYOTP: "verifyotp",
  NEWPASSWORD: "setnewpassword",
  CHANGEPASSWORD: "changepassword",
  UPDATEUSERDATA: "updateuserdata",
  INSERTATTRIBUTE: "insert-attribute",
  GETATTRIBUTES: "get-allattribute",
  CHANGEATTRSTATUS: "update-attrstatus",
  UPDATEATTRIBUTE: "update-attribute",
  INSERTBRANCH: "insert-branch",
  GETBRANCH: "get-allbranch",
  UPDATEBRANCH: "update-branch",
  DELETEBRANCH: "delete-branch",
  TYPECOUNTRY: "get-typecountry",
  INSERTSERVICE: "insert-service",
  GETSERVICEPRICE: "get-allservice",
  DELETESERVICE: "delete-service",
  INSERTFEATURE: "insert-feature",
  GETFEATURE: "get-allFeature",
  UPDATEFEATURE: "update-feature",
  DELETEFEATURE: "delete-feature",
  GETLEADSOURCE: "get-leadsource",
  INSERTLEAD: "insert-lead",
  GETALLLEAD: "get-alllead",
  DELETELEAD: "delete-lead",
  REMOVELEADFROMPICKUPDONE: "remove-leadfrompickupdone",
  UPDATELEAD: "update-lead",
  GETALLBRANCH: "get-allbranch",
  GETUSERS: "get-users",
  GETALLLEADSTATUS: "get-allleadstatus",
  UPDATELEADBRANCH: "update-leadbranch",
  UPDATELEADSTATUS: "update-leadstatus",
  UPDATELEADPRIORITY: "update-leadpriority",
  INSERTFOLLOWUP: "insert-followup",
  GETFOLLOWUP: "get-followup",
  UPDATESETTING: "update-setting",
  GETSETTINGDATA: "get-setting",
  //SMS Template
  INSERTSMSTEMPLATE: "insert-smstemplate",
  GETALLSMSTEMLATE: "get-allsmstemlate",
  DELETESMSTEMPLATE: "delete-smstemplate",
  UPDATESMSTEMLATE: "update-smstemplate",
  CHANGETEMPLATESTATUS: "change-templatestatus",
  UPDATEALLOCATED: "update-allocatedto",

  //JIl Sutariya siraa
  GETTEMPLATECATEGORY: "get-template-category",
  GETTEMPLATESUBCATEGORY: "get-template-subcategory",
  INSERTEMAILTEMPLATE: "insert-emailTemplate",
  GETEMAILTEMPLATE: "get-emailtemplate",
  UPDATEEMAILTEMPLATE: "update-emailtemplate",
  DELETEEMAILTEMPLATE: "delete-emailtemplate",
  GETTODAYLEADREMINDER: "gettodayleadfollowup",

  //tasknotification
  GETNEWTASK: "get-newtasknotification",
  READTASKNOTIFICATION: "readtasknotification",
  GETTASKBYID: "get-taskbyid",

  //sakshi
  INSERTROLE: "insert-role",
  GETALLROLELIST: "get-allrole",
  GETROLEPERMISSION: "get-rolepermission",
  UPDATEROLE: "update-role",
  INSERTUSER: "insert-user",
  GETALLUSERBYPARENT: "get-userbyparent",
  GETUSERPERMISSION: "get-userpermission",
  UPDATEUSER: "update-user",
  DELETEUSER: "delete-user",
  SEARCHLEAD: "search-lead",
  GETINVOICEDATA: "get-invoicedata",
  UPDATECADDRESS: "update-customeraddress",
  FETCHBRANCHDATABYID: "fetch-branchdatabyid",
  FETCHGRAPHLEADDATA: "fetch-graphleaddata",
  INSERTMANAGERALLOCATION: "insert-managerallocation",
  GETALLOCATIONLIST: "get-allocationlist",
  GETBRANCHALLOCATIONLIST: "get-branchallocationlist",
  UPDATEMANAGERALLOCATION: "update-managerallocation",
  DELETEMANAGERALLOCATION: "delete-managerallocation",
  DELETEALLOCATEBRANCHBYID: "delete-allocatedbranchbyid",
  SENDINVOICEMAIL: "send-invoicemail",
  DOWNLOADALLPDF: "download-allinvoicepdf",
  GETSELECTIONWISEAMT: "get-selectionamount",
  FETCHMANAGERBRANCH: "fetch-managerbranch",
  GETUSERBYTYPE: "getuserbytype",
  FETCHBRANCHUSERDATA: "fetch-branchuserdata",
  GETREVENUEDATA: "get-revenuedata",
  GETTEMPLISTBYCATE: "get-templatelistbycate",
  GETTEMPLATEDATA: "get-templatedata",
  GETCOURIERCHARGEREPORT: "get-courierchargereport",
  GETPICKUPCHARGEREPORT: "get-pickupchargereport",
  GETTOTALINVOICE: "get-totalinvoice",
  SENDMAILFORTRANSLATION: "send-mailfortranslation",
  GETCOURIERREPORT: "get-courierreport",
  GETALLCOURIERREPORT: "get-allcourierreport",
  SEARCHALLCOVER: "search-allcover",
  ALLCOVERPDF: "all-coverpdf",
  SEARCHSTATUSWISELEADREPORT: "search-statuswiseleadreport",
  GETALLPROCESSOVERDUE: "get-allprocessoverdue",
  SETNEXTPROCESSFINISHDATE: "set-nextprocessfinishdate",
  GETFAQCATEPERMISSION: "get-faqcatepermission",
  GETALLPICKUPUSERS: "get-allpickupusers",
  //Branch Setting
  GETBRANCHDATA: "get-branchdata",
  UPDATEBRANCHSETTING: "update-branchsetting",
  DELETEEMAILIMG: "delete-emailimage",
  //Task
  INSERTTASK: "insert-task",
  GETALLTASK: "get-alltask",
  DELETETASK: "delete-task",
  DELETETASKIMG: "delete-taskimage",
  UPDATETASK: "update-task",
  UPDATETASKPRIORITY: "update-taskpriority",
  UPDATETASKSTATUS: "update-taskstatus",
  INSERTTASKFEEDBACK: "insert-taskfeedback",
  GETFEEDBACK: "get-taskfeedback",
  UPDATETASKALLOCATED: "update-taskallocatedto",
  INSERTCONFIRMATION: "update-taskrevision",
  SEARCHTASKDATA: "search-taskdata",
  GETCOUNTALLTASK: "get-totaltask",
  GETCOUNTALLLEAD: "get-totallead",
  GETCOUNTALLTODAYLEAD: "get-totaltodaylead",
  CHECKCUSTOMER: "check-customer",
  INSERTCUSTOMER: "insert-maincustomer",
  GETCUSTOMERLIST: "get-allcustomer",
  GETUSER: "get-user",
  GETATTRIBUTESBYID: "get-attributebyid",
  GETCUSTOMERAMOUNT: "get-customer-amount",
  UPDATETASK: "update-task",
  UPDATETASKPRIORITY: "update-taskpriority",
  UPDATETASKSTATUS: "update-taskstatus",
  INSERTTASKFEEDBACK: "insert-taskfeedback",
  GETFEEDBACK: "get-taskfeedback",
  UPDATETASKALLOCATED: "update-taskallocatedto",
  INSERTCONFIRMATION: "update-taskrevision",
  //Visa
  GETALLVISALIST: "get-visacustomer",
  UPDATEVISASTATUS: "update-visastatus",
  DELETEVISA: "delete-visacustomer",
  UPDATEVISA: "update-visacustomer",
  SEARCHLVISA: "search-visacustomer",
  //change
  GETVISABYID: "get-visacustomerbyid",
  //Attestation
  GETALLATTESTATIONLIST: "get-attestationcustomer",
  UPDATEATTESTATIONSTATUS: "update-attestationstatus",
  DELETEATTESTATION: "delete-attestationcustomer",
  UPDATEATTESTATION: "update-attestationcustomer",
  SEARCHATTESTATION: "search-attestationcustomer",
  //Apostille
  GETALLAPOSTILLELIST: "get-apostillecustomer",
  UPDATEAPOSTILLESTATUS: "update-apostillestatus",
  DELETEAPOSTILLE: "delete-apostillecustomer",
  UPDATEAPOSTILLE: "update-apostillecustomer",
  SEARCHAPOSTILLE: "search-apostillecustomer",
  GETAPOSTILLEBYID: "get-apostillecustomerbyid",
  //Customer Comment
  INSERTCUSTOMERCONVERSATION: "insert-customerconversation",
  GETCOMMENT: "get-customercomment",
  //Customer File
  UPLOADCUSTOMERFILE: "upload-customerfile",
  GETALLFILESBYID: "get-allfilesbyid",
  DELETEFILE: "delete-file",
  //customer-payment
  GETPAYMENT: "get-payment",
  UPDATEPAYMENT: "update-payment",
  UPDATEPAYMENTWITHADDITIONALCHARGES: "update-paymentWithAdditionalCharges",
  UPDATEPAYMENTWITHTDSAMOUNT: "update-paymentWithTdsAmount",

  SEARCHCUSTOMERLIST: "search-customer",
  GETCUSTOMERBYID: "get-customer",
  UPDATECUSTOMER: "update-customer",
  DELETECUSTOMER: "delete-customer",
  GETREFUNDAMT: "get-paymentrefund",
  //payment
  GETPAYMENTLIST: "get-paymentdetail",
  SEARCHPAYMENT: "search-payment",
  //Refund
  INSERTREFUND: "insert-refundpayment",
  GETPAYMENTDETAILS: "get-paymentdetails",
  GETALLREFUNDDATA: "get-paymentrefund",
  SEARCHREFUND: "search-refunddata",
  GETSETTINGDATABYID: "get-settingdatabyid",
  //cashflow
  GETBRANCHUSER: "get-branchuser",
  GETCASHPAYMENT: "get-cashpayment",
  INSERTCASHFLOW: "insert-cashflow",
  SEARCHCASHFLOW: "search-cashflow",
  DELETECASHFLOW: "delete-cashflow",
  UPDATEAPPLICATIONSTATUS: "update-detailsstatus",
  GETALLUNDERPROCESSLIST: "get-underprocesslist",
  //TDS
  GETTDSLIST: "get-tds",
  UPDATETDS: "update-tds",
  GETATTESTATIONBYID: "get-attestationcustomerbyid",
  //coverletter
  GETBUSINESSSUMMARY: "get-businesssummary",
  SEARCHBUSINNESSSUMMARY: "search-businesssummary",
  //attestationcover
  SEARCHATTESTATIONCOVER: "search-attestationcover",
  ATTESTATIONPDF: "attestation-pdf",
  //apostillecover
  SEARCHAPOSTILLECOVER: "search-apostillecover",
  APOSTILLEPDF: "apostille-pdf",
  //visacover
  SEARCHVISACOVER: "search-visacover",
  VISAPDF: "visa-pdf",
  SENDATTESTATIONMAIL: "send-attestationmail",
  SENDAPOSTILLEMAIL: "send-apostillemail",
  SENDVISAMAIL: "send-visamail",
  //dispatch
  SEARCHDISPATCH: "get-dispatch",
  DISPATCHPDF: "dispatch-pdf",
  //excelcover
  SEARCHEXCELCOVER: "search-excelreports",
  //clientaccount
  GETBRANCHCUSTOMER: "get-branchcustomer",
  SEARCHACCOUNTREPORT: "search-accountreports",
  CLIENTACCOUNTPDF: "accountreport-pdf",
  //profitReport
  SEARCHPROFITREPORT: "profit-cost",

  //Refund-paymentreceipt
  GETREFUNDBYID: "get-refundbyid",
  GETREFUNDRECEIPT: "get-refundreceipt",
  GETPAYMENTDATABYID: "get-paymentdata",
  GETPAYMENTRECEIPT: "get-paymentreceipt",
  //Registration Report
  GENERATECREGISTRATIONREPORT: "generate-cregistrationreport",
  //Ready For Delivery Report
  GENERATERDELIVERYREPORT: "generate-rdeliveryreport",
  GENERATEALLRDELIVERYREPORT: "generate-allrdeliveryreport",
  GENERATERDELIVERYHISTORYREPORT: "generate-rdeliveryhistoryreport",
  GENERATEALLRDELIVERYHISTORYREPORT: "generate-allrdeliveryhistoryreport",
  //Send Mail in Refund
  SENDREFUNDMAIL: "send-refundmail",
  //Send Mail in Payment
  SENDPAYMENTMAIL: "send-paymentmail",
  SEARCHLEADREPORT: "lead-report",
  SEARCHLEADCOUNTREPORT: "search-leadcountreport",
  SEARCHCUSTOMERCOUNTREPORT: "search-customercountreport",
  SEARCHLEADALLOCATEREPORT: "search-adminallocatereport",
  CONVERTLEAD: "convert-lead",
  GETCOUNTFOLLOWUP: "get-leadfollowupcounter",
  SEARCHLEADFOLLOWUPREPORT: "search-leadfollowupcountreport",
  //fullcopy
  INSERTFULLCOPY: "fullcopy",
  //halfcopy
  INSERTHALFCOPY: "halfcopy",
  //Proforma Invoice
  INSERTLEADCUSTOMER: "insert-leadcustomer",
  GETPROFORMAINVOICEDATA: "get-proformainvoicedata",
  GETPROFORMAINVOICE: "get-proformainvoice",
  //update-service
  UPDATESERVICE: "update-service",
  //update-dispatchdate
  UPDATEDISPATCHDATE: "update-dispatchdate",
  //Emailtemplatelist
  GETALLEMAILTEMLATE: "get-allemailtemplate",
  //Send Lead Mail
  SENDLEADMAIL: "send-leadmail",
  //Branchtransfer
  BRANCHTRANSFER: "transfer-branch",

  /////////////////////////////////////         customer                 ////////////////////////////////////////////////////
  //login
  CUSTOMERLOGIN: "customer-login",
  //customer
  GETCUSTOMERS: "get-customers",
  SEARCHCUSTOMERS: "search-customers",
  //attestation
  GETATTESTATIONS: "get-attestations",
  SEARCHATTESTATIONS: "search-attestations",
  //apostille
  GETAPOSTILLES: "get-apostilles",
  SEARCHAPOSTILLES: "search-apostilles",
  //translationcust
  GETTRANSLATIONS: "get-translations",
  SEARCHTRANSLATIONS: "search-translations",
  //visa
  GETVISAS: "get-visas",
  SEARCHVISAS: "search-visas",
  //payment
  GETPAYMENTS: "get-payments",
  SEARCHPAYMENTS: "search-payments",
  //tds
  GETCUSTOMERTDSLIST: "get-customertdslist",
  SEARCHTDSDATA: "search-tdsdata",
  //applicant
  GETAPPLICANTS: "get-applicants",
  //feedback
  INSERTCUSTOMERFEEDBACK: "insert-feedback",
  GETCUSTOMERFEEDBACK: "get-feedback",
  //invoice
  GETALLINVOICE: "get-allinvoice",
  //Goal
  INSERTGOAL: "insert-goal",
  GETALLGOAL: "get-allgoal",
  UPDATEGOAL: "update-goal",
  DELETEGOAL: "delete-goal",
  //Goal And Task On dashboard
  GETALLGOALLIST: "get-allgoallist",
  GETALLDASHTASK: "get-alldashtask",
  //Get Task User
  GETTASKUSERS: "get-taskusers",
  //Get activitylog by id
  GETACTIVITYLOGBYID: "get-activitylog",

  ADDLOGOUTTIME: "add-logouttime",
  SEARCHLOGINACTIVITYDATA: "search-login_logout_report",

  GETLOGINACTIVITYDATA: "login_logout_report",
  //Hold status application in dashboard
  GETALLHOLDAPPLICATION: "get-allholdapplication",
  INSERTHOLDCOMMENT: "insert-nextholdcomment",

  GETALLDISPATCHINGAPPLICATIONS: "get-alldispatchingapplications",
  //Translation
  GETALLTRANSLATIONLIST: "get-translationcustomer",
  GETTRANSLATIONBYID: "get-translationcustomerbyid",
  DELETETRANSLATION: "delete-translationcustomer",
  UPDATETRANSLATION: "update-translationcustomer",
  SEARCHTRANSLATION: "search-translationcustomer",
  TRANSLATIONPDF: "translation-pdf",
  SEARCHTRANSLATIONCOVER: "search-translationcover",
  SENDTRANSLATIONMAIL: "send-translationmail",

  BLOCKCUSTOMER: "block-customer",
  //Send mail with pdf and without pdf in lead proforma invoice
  SENDLEADINVOICEMAIL: "send-leadinvoicemail",
  SENDLEADEMAIL: "send-leademail",
  // Jil
  CHANGECUSTOMERPASSWORD: "changecustomerpassword",
  SENDTEMPMAIL: "send-tempmail",

  // Inquiry Form
  GETUSERBYID: "get-userbyid",
  // FAQ

  GETFAQCATEGORY: "getfaqcategory",
  INSERTFAQCATEGORY: "insertfaqcategory",
  UPDATEFAQCATEGORY: "updatefaqcategory",

  INSERTFAQ: "insertfaq",
  GETFAQ: "getfaq",
  UPDATEFAQ: "updatefaq",
  DELETEFAQ: "deletefaq",

  DELETEFAQCATEGORY: "deletefaqcategory",
  GETPROCESSLIMITDATE: "get-ProcessLimitDate",
  // TARGET
  INSERTTARGET: "inserttarget",
  GETTARGET: "gettarget",
  UPDATETARGET: "updatetarget",
  GETUSERTARGETREPORT: "usertargetreport",
  //Draft Receipt
  APPROVEDPAYMENT: "approved-payment",
  DELETEPAYMENT: "delete-draftpayment",
  GETDRAFTRECEIPTLIST: "get-draftreceipt",
  GETDRAFTPAYMENTDATABYID: "get-draftpaymentdata",
  GETDRAFTPAYMENTRECEIPT: "get-draftpaymentreceipt",
  SEARCHDRAFTPAYMENT: "search-draftpayment",

  // JIL Customer Summary Report
  SEARCHACCOUNTSUMMARY: "search-accountsummary",
  //Suggestion
  INSERTSUGGESTION: "insert-suggestion",
  GETALLSUGGESTION: "get-suggestion",
  UPDATESUGGESTION: "update-suggestion",
  DELETESUGGESTION: "delete-suggestion",
  //Monthwise target report
  USERMONTHTARGETREPORT: "usermonthtargetreport",
  DELETETARGET: "delete-usertarget",
  //Main Customer
  GETALLMAINCUSTOMER: "get-maincustomer",
  UPDATEMAINCUSTOMER: "updatemaincustomerdetails",
  SENDLOGINMAIL: "send-loginmail",
  SEARCHMAINCUSTOMERDATA: "search-maincustomer",

  GETEMBASSYFEELIST: "get-embassyfees",
  INSERTEMBASSYFEES: "insert-embassyfee",
  UPDATEEMBASSYFEE: "update-embassyfees",

  GETCOURIERCHARGELIST: "get-couriercharge",
  INSERTCOURIERCHARGES: "insert-couriercharge",
  UPDATECOURIERCHARGE: "update-couriercharge",
  SEARCHCOURIERCHARGES: "search-couriercharge",
  GETCOURIERCHARGEAMOUNT: "get-courierchargeamount",

  SEARCHCHARGEREPORT: "search-chargereport",
  GETEMBASSYAMOUNT: "get-embassy-amount",
  GETALLNEWLEAD: "get-allnewlead",
  GENERATECOLLECTEDREPORT: "generate-collectedreport",
  GENERATEDISPATCHEDREPORT: "generate-dispatchreport",
  //embassyFeesreport
  SEARCHEMBASSYFEESREPORT: "search-embassyfeesreport",
  GETALLNEWCUSTOMEROFANOTHERBRANCH: "get-newCustomerofAnotherBranch",
  GETALLDRAFTRECEIPT: "get-alldraftreceipt",
  GETALLCASHFLOW: "get-allcashflow",
  READALLCASHFLOW: "read-cashflow",
  GETALLAPPLICANTS: "get-allapplicants",
  GETMONTHINVOICE: "get-TotalMonthlyInvoice",
  GETALLINPICKUPLIST: "get-allinpickuplist",
  INSERTPICKUPNOTE: "insert-pickupnote",
  GETALLOUTPICKUPLIST: "get-alloutpickuplist",
  PICKUPDONE: "pickup-done",
  GETALLPICKUPDONELIST: "get-allpickupdonelist",
  GETALLPICKUPDONELEADLIST: "get-allpickupdoneleadlist",
  SEARCHALLPICKUPDONELEADLIST: "search-allpickupdoneleadlist",
  INSERTQUESTION: "insert-question",
  GETALLQUESTION: "get-allquestion",
  DELETEQUESTION: "delete-question",
  UPDATEQUESTION: "update-question",
  GETALLQUESTIONLIST: "get-dailyquestions",
  INSERTDAILYQUEANS: "insert-dailyqueans",
  GETUSERSCHAT: "get-userschat",
  GETCUSTOMERCHAT: "get-chatbyid",
  INSERTUSERMESSAGE: "insert-usermessage",
  SEARCHMAINCUSTOMERCHAT: "search-maincustomerchat",
  GETCHATALLCUSTOMER: "get-allchatcustomer",
  GETCUSTOMERUSER: "get-customeruser",
  GETTEMPLATEBODY: "get-templatebody",
  GETEMAILHISTORY: "get-emailhistory",
  GETDRAFTRECEIPTCOUNTER: "get-draftreceiptcounter",
  SEARCHTASKREPORT: "get-taskreport",
  //tdsreport
  // GETTDSREPORT: "get-tdsreport",
  SEARCHTDSREPORT: "search-tdsreport",
  UPDATEDRAFTPAYMENT: "update-draftpayment",
  UPDATEPAYMENTMODE: "update-paymentmode",
  GETSERVICEVALUE: "get-servicevalue",
  INSERTDIRCUSTOMER: "insert-dircustomer",
  INSERTBANKDETAILS: "insert-bankdetails",
  GETBANKDETAILS: "get-bankdetails",
  DELETEBANKDETAILS: "delete-bankdetails",
  DRAFTRECEIPTREAD: "draftreceipt-read",
  BRANCHCUSTOMERREAD: "branchcustomer-read",
  NEWLEADREAD: "newlead-read",
  GETSERVICEVALUETYPE: "get-servicevaluetype",
  NEWPICKDONEREAD: "newpickdone-read",
  LEADFOLLOWREAD: "leadfollow-read",
  SEARCHSERVICEPRICE: "search-serviceprice",
  GETREFERRALCUSTOMER: "get-referralcustomer",
  GETMAINCUSTOMERBYID: "get-maincustomerbyid",
  GETCOMMISIONDATA: "get-commisiondata",
  UPDATECOMMISIONPAYMENT: "update-commsionpayment",
  GETCOMMISIONREPORTDATA: "get-commissionreportdata",
  DELETECOMMISION: "delete-commision",
  CHECKCOMMISSIONDATABYID: "check-commissiondatabyid",
  SEARCHEMBASSYFEES: "search-embassyfees",
  SEARCHFEEDBACK: "search-feedback",
  UPDATEPAYMENTWITHAPPROVAL: "update-paymentwithapproval",
  GETATTESTATIONLISTBYCID: "get-attestationlistbycid",
  SEARCHCASHINHAND: "search-cashinhand",
  SEARCHEMBASSYFEESREPORTALL: "search-embassyfeesreportall",
  GETLEADFOLLOWUP: "get-leadfollowup",
  GETUSERLIST: "get-userlist",
  GETALLCHAT: "get-allchatnotification",
  USERCHECK: "user-check",
  GETTODAYCUSTOMERREMINDER: "gettodaycustomerfollowup",
  CUSTOMERFOLLOWREAD: "customerfollow-read",
  GETCOUNTCFOLLOWUP: "get-customerfollowupcounter",
  SEARCHATTRIBUTE: "search-attribute",
  GETAPOSTILLELISTBYCID: "get-apostillelistbycid",
  GETTRANSLATIONLISTBYCID: "get-translationbycid",
  GETVISALISTBYCID: "get-visabycid",
  UPDATEUNDERPROCESSDATE: "update-underprocessdate",
  SENDPOLICYMAIL: "send-policymail",

  SENDCODEMAIL: "send-codemail",
  GETDETAILID: "get-detailid",

  SEARCHPICKUPDONECOURIERREPORT: "search-pickupdonecourierreport",
  GETMULTIINVOICESINGLECUSTOMER: "get-multiinvoicesinglecustomer",
  DELETETDS: "delete-paymentWithTdsAmount",

  GETCASHFLOWDETAILS: "get-cashflowdetails",
  GETCASHFLOWDETAILSPDF: "get-cashflowdetailspdf",

  GETCOMMISSIONDETAILS: "get-commissiondetails",
  GETCOMMMISSIONDETAILSPDF: "get-commissiondetailspdf",

  GETLEAVEDATA: "getleavedata",
  INSERTLEAVE: "insertleave",
  UPDATELEAVE: "updateleave",
  UPDATELEAVESTATUS: "updateleavestatus",

  DELETEPROFORMA: "delete-leadcustomer",

  GETLEADCUSTOMERBYID: "get-leadcustomer",
  UPDATELEADCUSTOMER: "update-leadcustomer",
  UPDATEPROFORMACUSTOMER: "update-proformacustomer",

  GETATTRIBUTENAMEBYID: "get-attributenamebyid",
  PICKUPSENTBYSELF: "pickup-sentbyself",

  GETALLPURCHASELIST: "get-purchaseList",
  GETALLPURCHASETDSLIST: "get-purchasetdsList",
  DELETEPURCHASETDS: "delete-purchasetds",
  DOWNLOADPURCHASETDSREPORT: "download-purchasetdsreport",
  GETCUSTOMERBYBRANCH: "get-customerbybranch",
  INSERTPURCHASE: "insert-purchase",
  UPDATEPURCHASE: "update-purchase",
  UPDATEPURCHASETDS: "update-purchasetds",
  UPDATEPURCHASESTATUS: "update-purchasestatus",
  GETPURCHASEPAYMENT: "get-purchasepayment",
  ADDPURCHASEPAYMENT: "add-purchasepayment",
  UPDTAEPURCHASEPAYMENT: "update-purchasePayment",
  GETPURCHASEPAYMENTLIST: "get-allpurchasepayment",
  DOWNLOADPURCHASEREPORT: "download-purchasereport",
  GETPURCHASEREPORT: "get-purchasereport",
  GETPURCHASETDSREPORT: "get-purchasetdsreport",
  ADDEXTRACOURIER: "insert-extracourier",
  UPDATEEXTRACOURIER: "update-extracourier",
  GETALLEXTRACOURIERLIST: "get-allextracouriers",
  DELETEEXTRACOURIER: "delete-extracourier",
  SENDLETTERCOURIERMAIL: "send-couriermail",
  GETCOVERLETTERCODE: "get-coverlettercode",
  GETALLWHATSAPPTEMPLATES: "get-whatsapptemplate",
  INSERTEHATSAPPTEMPLATE: "insert-whatsapptemplate",
  UPDATEWHATSAPPTEMPLATE: "update-whatsapptemplate",
  SENDLEADWHATSAPPMESSAGE: "send-leadwhatsappmessage",
  SENDCUSTOMERWHATSAPPMESSAGE: "send-customerwhatsappmessage",
  SENDPICKUPOUTMAIL: "send-pickupoutmail",
  SENDBULKMAIL: "send-bulkmail",
  SENDBULKMESSAGE: "send-bulkmessage",
  SENDNOSERVICEMAIL: "send-noservicemail",

  UPDATEWPAPIKEY: "updatewpapikey",

  WAPMONKEYLINK: "linktowapmonkey",
  WAPGETUSERDEVICE: "getuserdevice",
  WAPREMAININGCREDITAPI: "remaining-credit-api",
  GETCOMMISSIONDROPDOWN: "get-commisiondropdowndata",
  GETLEAVENOTIFICATION: "get-leavenotification",
  GETPICKUPFROMCITYOFFICE: "get-pickupfromcityoffice",
  GETPICKUPFROMCITYOFFICEREPORT: "get-pickupfromcityofficereport",
  INSERTPICKUPFROMCITYOFFICE: "insert-pickupfromcityoffice",
  UPDATEPICKUPFROMCITYOFFICE: "update-pickupfromcityoffice",
  DELETEPICKUPFROMCITYOFFICE: "delete-pickupfromcityoffice",
  READPICKUPDONENOTIFICATION: "read-pickupdonenotification",
  PICKUPSENDINGBYSELF: "pickup-sendingbyself",
  PICKUPDONEBYCUSTOMER: "pickup-donebycustomer",
  UPDATEPROCESSDATE: "update-processdate",
  GETALLSOLOBRANCHLIST: "get-solobranchlist",
  CHECKNETWORK: "check-network",
  SEARCHREADYFORDISPATCHFORSCAN: "get-readyfordispatchforscan",
  SENDSCANDOCUMENTMAIL: "send-scandocumentmail",
  GENERATEPICKUPEXCELREPORT: "generate-pickupexcelreport",
  ChangeIPCHECK: "change-ipcheck",
  GETSCHEDULEDMAILDATA: "get-scheduledmaildata",
  DELETESCHEDULEMAILDATA: "delete-schedulemaildata",
  SENDTESTMAIL: "send-testmail",
  UPDATEBILLINGDETAILS: "update-billingsetting",
  GEYSUPPORTINGDOCBYCOUNTRY: "get-countrywisedoc",
  GETLEADTEMPLATEFORBULKMAIL: "getleadtemplateforbulkmail",
  GETEMBASSYBYCOUNTRY: "get-embassybycountry",
  GETCOURIERBYCOUNTRY: "get-courierchargebycountry",
  GENERATEAUTOPROFORMA: "generate-autoproforma",
  GENERATEAUTOINVOICE: "generate-autoinvoice",
  GENERATECREDITNOTE: "generate-creditnote",
  GETCREDITINVOICEDATA: "get-creditinvoicedata",
  GENERATECREDITNOTEPDF: "generate-creditnotepdf",
  CREDITSENDINVOICEMAIL: "send-creditinvoicemail",
  UPDATECREDITNOTE: "update-creditnote",
  DELETECREDITNOTE: "delete-creditnote",
  UPDATEPAYMENTWITHCREDITNOTE: "update-paymentWithCreditNote",
  UPDATECOURIERCHARGESTATUS: "update-courierchargestatus",
  UPDATEREADTFORDISPATCHSTATUS: "update-readyfordispatchstatus",
  GETTEMPLATEWITHOUTSIGNATURE: "get-templatebodywithoutsignature",
  GETBULKTEMPLATEFORBULKMAIL: "getbulktemplateforbulkmail",
  GETLASTDRAFTOFAUTOMAIL: "getlastdraftofautomail",
  CREATEPROFORMADRAFTPAYMENT: "createproformadraftpayment",
  DELETEDRAFTPROFORMA: "deleteproformadraftpayment",
  UPLOADLEADATTACHMENTS: "upload-leadattachments",
  GETLEADATTACHMENTS: "get-allfilesbyleadid",
  SENDCODEEMAIL: "sendcodeemail",
  UPDATEDETAILSTATUSBYCUSTOMER: "update-detailstatusbycustomer",
  HANDLETRANSACTIONCHANGE: "handletransactionchange",
};
