import {
  CButton,
  CCard,
  CCardBody,
  CCardGroup,
  CCardHeader,
  CCol,
  CContainer,
  CForm,
  CFormInput,
  CFormLabel,
  CRow,
} from "@coreui/react";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import {
  getCustomerbyid,
  getPaymentDetails,
  updateDetailStatusByCustomer,
} from "src/api/commonapi";
import { Routes } from "src/constants/api_url";
import Select from "react-select";
import CustomImage from "src/views/CommonFunctions/CustomImage";
import { fetchAttributesbyid } from "src/api/common";
import { compareById } from "src/views/CommonFunctions/common";
import { notification } from "src/constants/message";
import cross from "./cross.jpg";
import { ClipLoader } from "react-spinners";

const DispatchingDocForm = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const code = queryParams.get("code");
  const defaultData = {
    courier_id: 0,
    city: "",
    pincode: "",
    n_d_address: "",
    d_address: "",
    is_courier: false,
  };
  const [companyname, setCompnyname] = useState([]);
  const [custData, setCustData] = useState(defaultData);
  const [submitResponse, setSubmitResponse] = useState(0);
  const [paymentData, setPaymentData] = useState();
  const [step, setStep] = useState(0);

  useEffect(async () => {
    let courierCompanyResponse = await fetchAttributesbyid(16);
    setCompnyname(courierCompanyResponse?.sort(compareById));
    const plain = Buffer.from(code, "base64").toString("utf8");
    let resp = await getCustomerbyid(parseInt(plain?.split("_")[0]));
    setCustData({
      city: resp.result.city,
      pincode: resp.result.pincode,
      n_d_address: resp.result.n_d_address,
      d_address: resp.result.d_address,
      courier_id: resp.result.courier_id || 0,
      is_courier: resp.result.courier_id == 0 ? false : true,
    });
    let paymentDetails = await getPaymentDetails(
      parseInt(plain?.split("_")[0])
    );

    setPaymentData(paymentDetails?.data);
  }, []);

  const updateDetailStatusFunction = async () => {
    let fdata = new FormData();
    if (custData?.city?.trim() == "" || custData?.d_address.trim() == "") {
      let notify = notification({
        type: "error",
        message: "Please add City or Address",
      });
      notify();
    } else {
      setStep(1);
      Object.keys(custData)?.map((key) => {
        fdata.append(key, custData[key]);
      });
      const plain = Buffer.from(code, "base64").toString("utf8");
      fdata.append("c_id", parseInt(plain?.split("_")[0]));
      const resp = await updateDetailStatusByCustomer(fdata);
      let notify = notification({
        type: resp.status == 0 ? "error" : "success",
        message: resp.message,
      });
      notify();
      if (resp.status == 0 || resp.status == 2) {
        setStep(0);
      }
      if (resp.status == 1) {
        setSubmitResponse(1);
        setStep(0);
        setCustData(defaultData);
      }
    }
  };

  return (
    <>
      <div className="d-flex flex-row">
        <CContainer>
          {paymentData ? (
            submitResponse == 0 &&
            (paymentData?.due_amt == 0 ||
              paymentData?.net_amt ==
                parseInt(paymentData?.paid_amt) +
                  parseInt(paymentData?.total_draft)) ? (
              <>
                <div className="center-hor-ver">
                  <div className="form-container-cus bg-white my-5">
                    <CRow>
                      <CCol md={12} className="text-center m-1">
                        <CustomImage
                          src={`${Routes.API_HOSTNAME}/images/adminlogo/loginlogo.png`}
                          className="cus_form_logo"
                        />
                        <h5
                          className="form_title text-center"
                          style={{ color: "black" }}
                        >
                          Kindly Review or Update the Form with Your Postal
                          Address.
                        </h5>
                      </CCol>
                      <CCol>
                        <CForm>
                          <div className="mb-3">
                            <CFormLabel className="" style={{ color: "black" }}>
                              Select Courier Company (Optional)
                            </CFormLabel>
                            <Select
                              value={companyname?.map((u) => {
                                if (u.attribute_id == custData?.courier_id) {
                                  return {
                                    value: u.attribute_id,
                                    label: u.name,
                                  };
                                } else return null;
                              })}
                              options={
                                companyname &&
                                companyname
                                  ?.filter(
                                    (item) =>
                                      !item?.name
                                        ?.toLowerCase()
                                        ?.startsWith("dhl") &&
                                      !item?.name
                                        ?.toLowerCase()
                                        ?.startsWith("fedex") &&
                                      !item?.name
                                        ?.toLowerCase()
                                        ?.startsWith("aramex") &&
                                      item?.attribute_id != 520 &&
                                      item?.attribute_id != 191 &&
                                      item?.attribute_id != 522 &&
                                      item?.attribute_id != 837 &&
                                      item?.attribute_id != 755
                                  )
                                  ?.map((ls) => {
                                    return {
                                      value: ls.attribute_id,
                                      label: ls.name,
                                    };
                                  })
                              }
                              onChange={(e) => {
                                setCustData({
                                  ...custData,
                                  courier_id: e.value,
                                });
                              }}
                              isDisabled={custData?.is_courier}
                            />
                          </div>
                          <div
                            style={{
                              background: "#e8e8e8",
                              padding: 8,
                              marginBottom: 5,
                            }}
                          >
                            <div className="mb-1">
                              <CFormLabel
                                className=""
                                style={{ color: "black" }}
                              >
                                City
                              </CFormLabel>
                              <CFormInput
                                value={custData?.city}
                                type="test"
                                id="city"
                                name="city"
                                onChange={(e) => {
                                  setCustData({
                                    ...custData,
                                    city: e.target.value,
                                  });
                                }}
                              />
                            </div>
                            <div className="mb-1">
                              <CFormLabel
                                className=""
                                style={{ color: "black" }}
                              >
                                Pincode
                              </CFormLabel>
                              <CFormInput
                                value={custData?.pincode}
                                type="test"
                                id="pincode"
                                name="pincode"
                                onChange={(e) => {
                                  setCustData({
                                    ...custData,
                                    pincode: e.target.value,
                                  });
                                }}
                              />
                            </div>
                            <div className="mb-1">
                              <CFormLabel
                                className=""
                                style={{ color: "black" }}
                              >
                                Address
                              </CFormLabel>
                              <textarea
                                name="d_address"
                                class="form-control"
                                style={{
                                  wordBreak: "break-word",
                                }}
                                placeholder="Client Address"
                                value={custData?.d_address}
                                rows={5}
                                onChange={(e) => {
                                  setCustData({
                                    ...custData,
                                    d_address: e.target.value,
                                  });
                                }}
                              ></textarea>
                            </div>
                          </div>

                          <CCol
                            className="mb-3"
                            style={{ textAlign: "center" }}
                          >
                            <CButton
                              disabled={step == 0 ? false : true}
                              color="primary"
                              onClick={() => {
                                updateDetailStatusFunction();
                              }}
                            >
                              Submit
                            </CButton>
                          </CCol>
                        </CForm>
                      </CCol>
                    </CRow>
                  </div>
                </div>
              </>
            ) : (
              <>
                {paymentData?.due_amt != 0 &&
                paymentData?.net_amt !=
                  parseInt(paymentData?.paid_amt) +
                    parseInt(paymentData?.total_draft) ? (
                  <CRow className="justify-content-center align-items-center">
                    <CCol md={5} style={{ width: "600px" }}>
                      <CCardGroup>
                        <CCard
                          className="p-4"
                          style={{ border: "1px solid black" }}
                        >
                          <h3
                            className="form_title text-center"
                            style={{ color: "black" }}
                          >
                            Your Payment is still Due
                          </h3>
                          <CCardBody>
                            <>
                              <CustomImage src={cross} className="cross_img" />
                              <CForm className="dsgrid">
                                <h5
                                  className="text-center mb-3"
                                  style={{ color: "#000" }}
                                >
                                  Please provide the Due Payment Details, Full
                                  address with PIN code, and Contact Number for
                                  Dispatching the Document
                                </h5>
                                <h2
                                  className="text-center mb-3"
                                  style={{ color: "#000" }}
                                >
                                  Thank You
                                </h2>
                              </CForm>
                            </>
                          </CCardBody>
                        </CCard>
                      </CCardGroup>
                    </CCol>
                  </CRow>
                ) : (
                  <CRow className="justify-content-center">
                    <CCol md={5} style={{ width: "600px" }}>
                      <CCardGroup>
                        <CCard className="p-4">
                          <CCardBody>
                            <>
                              <CForm className="dsgrid">
                                <CustomImage
                                  src={`${Routes.API_HOSTNAME}/images/adminlogo/check.png`}
                                  className="mb-3 center_img"
                                />
                                <h5
                                  className="text-center mb-3"
                                  style={{ color: "#000" }}
                                >
                                  We have noted the completion of the
                                  Dispatching Process.
                                </h5>
                                <h2
                                  className="text-center mb-3"
                                  style={{ color: "#000" }}
                                >
                                  Thank You
                                </h2>
                              </CForm>
                            </>
                          </CCardBody>
                        </CCard>
                      </CCardGroup>
                    </CCol>
                  </CRow>
                )}
              </>
            )
          ) : (
            <>
              <div className="center-hor-ver">
                <div className="form-container-cus bg-white my-5">
                  <CRow>
                    <CCol md={12} className="text-center mt-3">
                      <h5
                        className="form_title text-center"
                        style={{ color: "black" }}
                      >
                        Please Wait
                      </h5>
                    </CCol>
                    <CCol md={12} className="text-center m-1 p-4">
                      <ClipLoader
                        color="#228ce4"
                        loading={true}
                        size={35}
                        aria-label="Loading Spinner"
                        data-testid="loader"
                      />
                    </CCol>
                  </CRow>
                </div>
              </div>
            </>
          )}
        </CContainer>
      </div>
    </>
  );
};

export default DispatchingDocForm;
